import { template as template_ecef4b0556364511b7e6134aa0bc123e } from "@ember/template-compiler";
const FKText = template_ecef4b0556364511b7e6134aa0bc123e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
