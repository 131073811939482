import { template as template_fd8c9eb162b74062bb9db805f2e6f021 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_fd8c9eb162b74062bb9db805f2e6f021(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
