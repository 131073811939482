import { template as template_59efb372c11e4806b0629454e49a88dd } from "@ember/template-compiler";
const FKLabel = template_59efb372c11e4806b0629454e49a88dd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
