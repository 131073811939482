import { template as template_f56eb55d915443e2b9464bc581c741db } from "@ember/template-compiler";
const WelcomeHeader = template_f56eb55d915443e2b9464bc581c741db(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
