import { template as template_5e55bd8817de4328abfe0450b5f54b69 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5e55bd8817de4328abfe0450b5f54b69(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
